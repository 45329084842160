export default {
  // Login Form
  Login: {
    wellcome_title: 'Bienvenido a FishNet! 👋🏻',
    wellcome_description: 'Inicia sesión en tu cuenta para comenzar la aventura',
    new_on_platform: '¿ Nuevo en la plataforma ?',
    create_an_account: 'Crea una cuenta',
    forgot_password: '¿ Olvidaste la contraseña ?',
    form: {
      username: 'Correo',
      username_placeholder: 'Correo',
      password: 'Password',
      password_placeholder: 'Password',
      rememberme: 'Recuerdame',
      submit: 'LOGIN',
    },
  },
  ForgotPassword: {
    forgot_password: '¿ Olvidaste la contraseña ? 🔒',
    forgot_password_description: 'Indique su correo y le enviaremos un enlace para indicar una nueva la contraseña',
    back_to_login: 'Ir a Iniciar sesión',
    form: {
      username: 'Correo',
      username_placeholder: 'Correo',
      submit: 'Solicitar enlace restablecer contraseña',
    },
  },
  ResetPassword: {
    reset_password: '¿ Restablecer Contraseña ? 🔒',
    reset_password_description: 'Tu nueva contraseña debe ser diferente de las anteriores',
    back_to_login: 'Ir a Iniciar sesión',
    form: {
      password: 'Nueva contraseña',
      password_placeholder: '············',
      confirm_password: 'Confirmar contraseña',
      confirm_password_placeholder: '············',
      submit: 'Establecer Nueva Contraseña',
    },
  },
  RegisterWizard: {
    header: {
      title: 'Información de Registro',
      subtitle: 'Solo para administradores',
      text: 'Estás a punto de dar de alta a tu empresa en la plataforma FishNet Expo creando la cuenta de administrador. Recomendamos que esta operación sea hecha con la autorización da Gerencia o del Responsable Comercial de la empresa',
      text_p2: 'Cada empresa podrá ter infinitas cuentas individuáis, pero todas ellas serán abiertas y gestionadas dende la cuenta de su administrador.',
      text_p3: 'Si tu empresa lla está registrada en FishNet Expo, habla con el administrador para que te de de alta.',
    },
    body: {
      tab1: {
        menu: 'Cuenta',
        title: 'Cuenta',
        subtitle: 'Introduzca los detalles se su cuenta como administrador',
        form: {
          email: 'Correo',
          email_placeholder: 'john@example.com',
          password: 'Contraseña',
          password_placeholder: 'Contraseña',
          first_name: 'Nombre',
          first_name_placeholder: 'Nombre',
          last_name: 'Apellidos',
          last_name_placeholder: 'Apellidos',
          language: 'Idioma de FishNet',
          agree: 'Condiciones',
          agree_term1: 'Acepto',
          agree_term2: 'la política de privacidad y los términos',
        },
      },
      tab2: {
        menu: 'Empresa',
        title: 'Empresa',
        subtitle: 'Introduzca la información de tu empresa',
        form: {
          name: 'Nombre',
          name_placeholder: 'Nombre de la empresa',
          phone: 'Teléfono',
          phone_placeholder: 'Teléfono de empresa',
          country: 'Pais',
          country_placeholder: 'Seleccione el pais',
          region: 'Región',
          region_placeholder: 'Seleccione una región',
          city: 'Ciudad',
          city_placeholder: 'Ciudad',
          zip: 'CP/ZIP',
          zip_placeholder: 'CP/ZIP',
          street: 'Calle',
          street_placeholder: 'Calle',
        },
      },
      tab3: {
        menu: 'Plan',
        title: 'Plan',
        subtitle: 'Seleccione el plan más conveniente para su empresa',
      },
    },
    footer: {
      action_back_to_login: 'Ir a Login',
      back: 'Anterior',
      next: 'Siguiente',
      finish: 'Registar',
    },
  },

  // Countries
  CountrySelect: {
    Afghanistan: 'Afganistán',
    Albania: 'Albania',
    Algeria: 'Algeria',
    'American Samoa': 'American Samoa',
    Andorra: 'Andorra',
    Angola: 'Angola',
    Anguilla: 'Anguilla',
    Antarctica: 'Antártida',
    'Antigua and Barbuda': 'Antigua and Barbuda',
    Argentina: 'Argentina',
    Armenia: 'Armenia',
    Aruba: 'Aruba',
    Australia: 'Australia',
    Austria: 'Austria',
    Azerbaijan: 'Azerbaijan',
    Bahamas: 'Bahamas',
    Bahrain: 'Bahrain',
    Bangladesh: 'Bangladesh',
    Barbados: 'Barbados',
    Belarus: 'Belarus',
    Belgium: 'Belgium',
    Belize: 'Belize',
    Benin: 'Benin',
    Bermuda: 'Bermuda',
    Bhutan: 'Bhutan',
    Bolivia: 'Bolivia',
    'Bonaire, Sint Eustatius and Saba': 'Bonaire, Sint Eustatius and Saba',
    'Bosnia and Herzegovina': 'Bosnia and Herzegovina',
    Botswana: 'Botswana',
    'Bouvet Island': 'Bouvet Island',
    Brazil: 'Brazil',
    'British Indian Ocean Territory': 'British Indian Ocean Territory',
    'Brunei Darussalam': 'Brunei Darussalam',
    Bulgaria: 'Bulgaria',
    'Burkina Faso': 'Burkina Faso',
    Burundi: 'Burundi',
    Cambodia: 'Cambodia',
    Cameroon: 'Cameroon',
    Canada: 'Canada',
    'Cape Verde': 'Cape Verde',
    'Cayman Islands': 'Cayman Islands',
    'Central African Republic': 'Central African Republic',
    Chad: 'Chad',
    Chile: 'Chile',
    China: 'China',
    'Christmas Island': 'Christmas Island',
    'Cocos (Keeling) Islands': 'Cocos (Keeling) Islands',
    Colombia: 'Colombia',
    Comoros: 'Comoros',
    'Congo, Republic of the (Brazzaville)': 'Congo, Republic of the (Brazzaville)',
    'Congo, the Democratic Republic of the (Kinshasa)': 'Congo, the Democratic Republic of the (Kinshasa)',
    'Cook Islands': 'Cook Islands',
    'Costa Rica': 'Costa Rica',
    Croatia: 'Croatia',
    Cuba: 'Cuba',
    Curaçao: 'Curaçao',
    Cyprus: 'Cyprus',
    'Czech Republic': 'Czech Republic',
    'Côte d Ivoire, Republic of': "Côte d'Ivoire, Republic of",
    Denmark: 'Denmark',
    Djibouti: 'Djibouti',
    Dominica: 'Dominica',
    'Dominican Republic': 'Dominican Republic',
    Ecuador: 'Ecuador',
    Egypt: 'Egypt',
    'El Salvador': 'El Salvador',
    'Equatorial Guinea': 'Equatorial Guinea',
    Eritrea: 'Eritrea',
    Estonia: 'Estonia',
    Ethiopia: 'Ethiopia',
    'Falkland Islands (Islas Malvinas)': 'Falkland Islands (Islas Malvinas)',
    'Faroe Islands': 'Faroe Islands',
    Fiji: 'Fiji',
    Finland: 'Finland',
    France: 'France',
    'French Guiana': 'French Guiana',
    'French Polynesia': 'French Polynesia',
    'French Southern and Antarctic Lands': 'French Southern and Antarctic Lands',
    Gabon: 'Gabon',
    'Gambia, The': 'Gambia, The',
    Georgia: 'Georgia',
    Germany: 'Germany',
    Ghana: 'Ghana',
    Gibraltar: 'Gibraltar',
    Greece: 'Greece',
    Greenland: 'Greenland',
    Grenada: 'Grenada',
    Guadeloupe: 'Guadeloupe',
    Guam: 'Guam',
    Guatemala: 'Guatemala',
    Guernsey: 'Guernsey',
    Guinea: 'Guinea',
    'Guinea-Bissau': 'Guinea-Bissau',
    Guyana: 'Guyana',
    Haiti: 'Haiti',
    'Heard Island and McDonald Islands': 'Heard Island and McDonald Islands',
    'Holy See (Vatican City)': 'Holy See (Vatican City)',
    Honduras: 'Honduras',
    'Hong Kong': 'Hong Kong',
    Hungary: 'Hungary',
    Iceland: 'Iceland',
    India: 'India',
    Indonesia: 'Indonesia',
    'Iran, Islamic Republic of': 'Iran, Islamic Republic of',
    Iraq: 'Iraq',
    Ireland: 'Ireland',
    'Isle of Man': 'Isle of Man',
    Israel: 'Israel',
    Italy: 'Italy',
    Jamaica: 'Jamaica',
    Japan: 'Japan',
    Jersey: 'Jersey',
    Jordan: 'Jordan',
    Kazakhstan: 'Kazakhstan',
    Kenya: 'Kenya',
    Kiribati: 'Kiribati',
    'Korea, Democratic People s Republic of': "Korea, Democratic People's Republic of",
    'Korea, Republic of': 'Korea, Republic of',
    Kuwait: 'Kuwait',
    Kyrgyzstan: 'Kyrgyzstan',
    Laos: 'Laos',
    Latvia: 'Latvia',
    Lebanon: 'Lebanon',
    Lesotho: 'Lesotho',
    Liberia: 'Liberia',
    Libya: 'Libya',
    Liechtenstein: 'Liechtenstein',
    Lithuania: 'Lithuania',
    Luxembourg: 'Luxembourg',
    Macao: 'Macao',
    'Macedonia, Republic of': 'Macedonia, Republic of',
    Madagascar: 'Madagascar',
    Malawi: 'Malawi',
    Malaysia: 'Malaysia',
    Maldives: 'Maldives',
    Mali: 'Mali',
    Malta: 'Malta',
    'Marshall Islands': 'Marshall Islands',
    Martinique: 'Martinique',
    Mauritania: 'Mauritania',
    Mauritius: 'Mauritius',
    Mayotte: 'Mayotte',
    Mexico: 'Mexico',
    'Micronesia, Federated States of': 'Micronesia, Federated States of',
    Moldova: 'Moldova',
    Monaco: 'Monaco',
    Mongolia: 'Mongolia',
    Montenegro: 'Montenegro',
    Montserrat: 'Montserrat',
    Morocco: 'Morocco',
    Mozambique: 'Mozambique',
    Myanmar: 'Myanmar',
    Namibia: 'Namibia',
    Nauru: 'Nauru',
    Nepal: 'Nepal',
    Netherlands: 'Netherlands',
    'New Caledonia': 'New Caledonia',
    'New Zealand': 'New Zealand',
    Nicaragua: 'Nicaragua',
    Niger: 'Niger',
    Nigeria: 'Nigeria',
    Niue: 'Niue',
    'Norfolk Island': 'Norfolk Island',
    'Northern Mariana Islands': 'Northern Mariana Islands',
    Norway: 'Norway',
    Oman: 'Oman',
    Pakistan: 'Pakistan',
    Palau: 'Palau',
    'Palestine, State of': 'Palestine, State of',
    Panama: 'Panama',
    'Papua New Guinea': 'Papua New Guinea',
    Paraguay: 'Paraguay',
    Peru: 'Peru',
    Philippines: 'Philippines',
    Pitcairn: 'Pitcairn',
    Poland: 'Poland',
    Portugal: 'Portugal',
    'Puerto Rico': 'Puerto Rico',
    Qatar: 'Qatar',
    Romania: 'Romania',
    'Russian Federation': 'Russian Federation',
    Rwanda: 'Rwanda',
    Réunion: 'Réunion',
    'Saint Barthélemy': 'Saint Barthélemy',
    'Saint Helena, Ascension and Tristan da Cunha': 'Saint Helena, Ascension and Tristan da Cunha',
    'Saint Kitts and Nevis': 'Saint Kitts and Nevis',
    'Saint Lucia': 'Saint Lucia',
    'Saint Martin': 'Saint Martin',
    'Saint Pierre and Miquelon': 'Saint Pierre and Miquelon',
    'Saint Vincent and the Grenadines': 'Saint Vincent and the Grenadines',
    Samoa: 'Samoa',
    'San Marino': 'San Marino',
    'Sao Tome and Principe': 'Sao Tome and Principe',
    'Saudi Arabia': 'Saudi Arabia',
    Senegal: 'Senegal',
    Serbia: 'Serbia',
    Seychelles: 'Seychelles',
    'Sierra Leone': 'Sierra Leone',
    Singapore: 'Singapore',
    'Sint Maarten (Dutch part)': 'Sint Maarten (Dutch part)',
    Slovakia: 'Slovakia',
    Slovenia: 'Slovenia',
    'Solomon Islands': 'Solomon Islands',
    Somalia: 'Somalia',
    'South Africa': 'South Africa',
    'South Georgia and South Sandwich Islands': 'South Georgia and South Sandwich Islands',
    'South Sudan': 'South Sudan',
    Spain: 'España',
    'Sri Lanka': 'Sri Lanka',
    Sudan: 'Sudan',
    Suriname: 'Suriname',
    Swaziland: 'Swaziland',
    Sweden: 'Sweden',
    Switzerland: 'Switzerland',
    'Syrian Arab Republic': 'Syrian Arab Republic',
    Taiwan: 'Taiwan',
    Tajikistan: 'Tajikistan',
    'Tanzania, United Republic of': 'Tanzania, United Republic of',
    Thailand: 'Thailand',
    'Timor-Leste': 'Timor-Leste',
    Togo: 'Togo',
    Tokelau: 'Tokelau',
    Tonga: 'Tonga',
    'Trinidad and Tobago': 'Trinidad and Tobago',
    Tunisia: 'Tunisia',
    Turkey: 'Turkey',
    Turkmenistan: 'Turkmenistan',
    'Turks and Caicos Islands': 'Turks and Caicos Islands',
    Tuvalu: 'Tuvalu',
    Uganda: 'Uganda',
    Ukraine: 'Ukraine',
    'United Arab Emirates': 'United Arab Emirates',
    'United Kingdom': 'United Kingdom',
    'United States': 'United States',
    'United States Minor Outlying Islands': 'United States Minor Outlying Islands',
    Uruguay: 'Uruguay',
    Uzbekistan: 'Uzbekistan',
    Vanuatu: 'Vanuatu',
    'Venezuela, Bolivarian Republic of': 'Venezuela, Bolivarian Republic of',
    Vietnam: 'Vietnam',
    'Virgin Islands, British': 'Virgin Islands, British',
    'Virgin Islands, US': 'Virgin Islands, U.S.',
    'Wallis and Futuna': 'Wallis and Futuna',
    'Western Sahara': 'Western Sahara',
    Yemen: 'Yemen',
    Zambia: 'Zambia',
    Zimbabwe: 'Zimbabwe',
    'Åland Islands': 'Åland Islands',
  },
}
